.navbar {
  position: fixed;
  width: 100%;
  text-shadow: 0 0 10px black;
  z-index: 999;
  background-color: transparent;
  font-size: var(--font-xs);
  transition: all ease .2s;
}

.navbar .navbar-header {
  transition: all ease .2s;
  padding: 1rem;
  background: none;
}

.navbar a {
  color: var(--p400);
}

.navbar a:hover {
  color: var(--s400);
}

.brand-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-brand {
  font-size: var(--font-md);
  text-transform: uppercase;
  font-weight: 700;
}

.width-limit {
  width: 100%;
  max-width: var(--width-limit);
  margin: auto; 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  gap: 2rem;
}

.navbar ul li a {
  display: flex;
  align-items: center;
  gap: .25rem;
  flex-direction: row;
  text-transform: uppercase;
  font-weight: 700;
}

#show-menu-button {
  cursor: pointer;
  display: flex;
  border: none;
  padding: .5rem;
  background-color: transparent;
}

@media (max-width: 900px) {
  .navbar {
    background-color: #000000bf;
  }

  #show-menu-button {
    display: block;
  }

  #nav-links {
    display: none;
  }
}

@media (min-width: 900px) {
  #show-menu-button {
    display: none;
  }

  #nav-links {
    display: block;
  }

  .navbar.navbar-shrink {
    transition: all ease .2s;
    font-size: var(--font-xs);
    background-color: #000000bf;
  }

  .navbar.navbar-shrink .navbar-header {
    padding: 0 1rem;
    transition: all ease .2s;
  }

  .navbar.navbar-shrink .navbar-brand {
    font-size: var(--font-md);
  }
}

#show-menu-button .bars {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.navbar-toggle .bars .icon-bar {
  border-radius: 3px;
  height: 1px;
  width: 22px;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--p400);
}

.navbar-toggle.active .bars .icon-bar {
  border-radius: 3px;
  height: 1px;
  width: 22px;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--s400);
}

.nav-item.active {
  color: var(--s400);
}