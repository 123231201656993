.navbar {
  text-shadow: 0 0 10px #000;
  z-index: 999;
  font-size: var(--font-xs);
  background-color: #0000;
  width: 100%;
  transition: all .2s;
  position: fixed;
}

.navbar .navbar-header {
  background: none;
  padding: 1rem;
  transition: all .2s;
}

.navbar a {
  color: var(--p400);
}

.navbar a:hover {
  color: var(--s400);
}

.brand-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.navbar-brand {
  font-size: var(--font-md);
  text-transform: uppercase;
  font-weight: 700;
}

.width-limit {
  max-width: var(--width-limit);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  display: flex;
}

.navbar ul {
  flex-direction: row;
  gap: 2rem;
  list-style: none;
  display: flex;
}

.navbar ul li a {
  text-transform: uppercase;
  flex-direction: row;
  align-items: center;
  gap: .25rem;
  font-weight: 700;
  display: flex;
}

#show-menu-button {
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: .5rem;
  display: flex;
}

@media (width <= 900px) {
  .navbar {
    background-color: #000000bf;
  }

  #show-menu-button {
    display: block;
  }

  #nav-links {
    display: none;
  }
}

@media (width >= 900px) {
  #show-menu-button {
    display: none;
  }

  #nav-links {
    display: block;
  }

  .navbar.navbar-shrink {
    font-size: var(--font-xs);
    background-color: #000000bf;
    transition: all .2s;
  }

  .navbar.navbar-shrink .navbar-header {
    padding: 0 1rem;
    transition: all .2s;
  }

  .navbar.navbar-shrink .navbar-brand {
    font-size: var(--font-md);
  }
}

#show-menu-button .bars {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.navbar-toggle .bars .icon-bar {
  border: 1px solid var(--p400);
  border-radius: 3px;
  width: 22px;
  height: 1px;
  transition: all .2s ease-in-out;
}

.navbar-toggle.active .bars .icon-bar {
  border: 1px solid var(--s400);
  border-radius: 3px;
  width: 22px;
  height: 1px;
  transition: all .2s ease-in-out;
}

.nav-item.active {
  color: var(--s400);
}
/*# sourceMappingURL=navbar.0ebb66c6.css.map */
